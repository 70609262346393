import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import {motion as m} from 'framer-motion';

export const Home = () => {
  return (
    <div className="w-full min-h-screen homeBack flex justify-center items-center">
      <m.div 
        initial={{opacity: 0}} 
        animate= {{opacity:1}}
        transition= {{duration:1, ease: "easeInOut"}}
        className="p-10">

        <img src={logo} alt="logo" width="297px" height="247px" />
        <p className="my-4 font-bold text-xl lg:text-2xl text-[#453631]">
          The easiest way to get setup by your friends.
        </p>
        <div className="my-6">
          <Link to="/apply">
            <button id="next" className="bg-white relative shadow-md hover:shadow-[#453631] w-40 p-2  rounded-md ease-out hover:translate-y-1 transition-all">
              Apply now
            </button>
          </Link>
        </div>
      </m.div>
    </div>
  );
};
