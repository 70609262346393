import logo from "../assets/logo.png";
import { ReactComponent as Filled } from "../assets/filledStep.svg";
import { ReactComponent as UnFilled } from "../assets/unfilledStep.svg";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";
import axios from "axios";
import React, { ChangeEvent } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
export const Apply = () => {
  const [step, setStep] = useState<number>(1);
  const increaseStep = () => {
    if (step < 11) {
      setStep(step + 1);
    }
  };

  const [isUnderage, setIsUnderage] = useState(false);

  const handleBirthdayChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const age = calculateAge(date.toISOString());
      setIsUnderage(age < 18);

      setValues({ ...values, birthday: date.format("YYYY-MM-DD") });
    }
  };

  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    birthday: "",
    interest: "",
    gender: "",
    selfID: "",
    forWho: "",
    countryCode: "+1",
    firstDate: "",
    beliefs: "",
    exFeedback: "",
    cantSayNo: "",
    friend1Name: "",
    friend1CountryCode: "US +1",
    friend1Phone: "",
    friend2Name: "",
    friend2CountryCode: "US +1",
    friend2Phone: "",
    location: "",
    instagramHandle: "",
  });

  const calculateAge = (birthday: string) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0); // Reset the time to 00:00:00, to ensure it's the start of the day

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleChangeNumber =
  (
    prop:
      | "phone"
      | "countryCode"
      | "friend1Phone"
      | "friend2Phone"
      | "friend1CountryCode"
      | "friend2CountryCode"
  ) =>
  (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    if (
      prop === "phone" ||
      prop === "friend1Phone" ||
      prop === "friend2Phone"
    ) {
      if (/^[\d\s()+-]*$/.test(value)) { // Allow digits, spaces, brackets, +, and -
        setValues({ ...values, [prop]: value });

        const digitsOnly = value.replace(/\D/g, ''); // Remove non-digit characters

        if (prop === "phone") {
          // Check if the phone number has a valid length (10-11 digits)
          setIsPhoneNumberValid(digitsOnly.length >= 10 && digitsOnly.length <= 11);
        } else if (prop === "friend1Phone") {
          // Check if the friend1Phone has a valid length (10-11 digits)
          setIsFriend1PhoneNumberValid(
            digitsOnly.length >= 10 && digitsOnly.length <= 11
          );
        } else if (prop === "friend2Phone") {
          // Check if the friend2Phone has a valid length (10-11 digits)
          setIsFriend2PhoneNumberValid(
            digitsOnly.length >= 10 && digitsOnly.length <= 11
          );
        }
      }
    } else if (
      prop === "countryCode" ||
      prop === "friend1CountryCode" ||
      prop === "friend2CountryCode"
    ) {
      setValues({ ...values, [prop]: value });
    }
  };



  const [waitlistPosition, setWaitlistPosition] = useState(444);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isFriend1PhoneNumberValid, setIsFriend1PhoneNumberValid] =
    useState(true);
  const [isFriend2PhoneNumberValid, setIsFriend2PhoneNumberValid] =
    useState(true);

  const handleChange =
    (name: any) =>
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      setValues({ ...values, [name]: e.target.value });
    };

  const handleItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { value, checked } = target;

    if (checked) {
      setValues({
        ...values,
        forWho: `${values.forWho}${values.forWho ? "," : ""}${value}`,
      });
    } else {
      setValues({
        ...values,
        forWho: values.forWho
          .split(",")
          .filter((item) => item !== value)
          .join(","),
      });
    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  const submitForm = async (data: any) => {
    try {
      const response = await fetch(
        "https://api.apispreadsheets.com/data/SLHQVwcJxmn8bhDc/",
        {
          method: "POST",
          body: JSON.stringify({ data }),
        }
      );

      if (response.status === 201) {
        // SUCCESS
        console.log("Form submitted successfully");
        //sendUserEmail();
        // sendInviteEmail();
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (step === 9) {
      // Prepare the form data to submit
      //const forWho = selectedItems.join(",");
      const formData = {
        name: values.name,
        email: values.email,
        gender: values.gender,
        forWho: values.forWho,
        beliefs: values.beliefs,
        birthday: values.birthday,
        interest: values.interest,
        cantSayNo: values.cantSayNo,
        firstDate: values.firstDate,
        exFeedback: values.exFeedback,
        countryCode: values.countryCode,
        friend1Name: values.friend1Name,
        friend2Name: values.friend2Name,
        selfID: values.selfID,
        phone: values.phone.replace(/\D/g, ''),
        friend1Phone: values.friend1Phone.replace(/\D/g, ''),
        friend2Phone: values.friend2Phone.replace(/\D/g, ''),
        location: values.location,
        instagramHandle: values.instagramHandle,
        friend1CountryCode: values.friend1CountryCode,
        friend2CountryCode: values.friend2CountryCode,
      };

      // Submit the form and show the thank you page
      setLoading(true);
      submitForm(formData)
        .then(() => {
          setLoading(false);
          const min = 444;
          const max = 708;
          const waitlistNumber =
            Math.floor(Math.random() * (max - min + 1)) + min;
          setWaitlistPosition(waitlistNumber);

          setStep(10);
        })
        .catch(() => {
          setLoading(false);
          // Handle the error (e.g., show an error message)
        });
    } else {
      setStep(step + 1);
    }
  };

  /* function handleSubmit(event: { preventDefault: () => void }) {
    // const selectedItemsString = selectedItems.join(",");
    // const forWho = selectedItems.join(",");
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("birthday", values.birthday);
    formData.append("gender", values.gender);
    formData.append("interest", values.interest);
    formData.append("selfID", values.selfID);
    formData.append("countryCode", values.countryCode);
    formData.append("firstDate", values.firstDate);
    formData.append("beliefs", values.beliefs);
    formData.append("exFeedback", values.exFeedback);
    formData.append("cantSayNo", values.cantSayNo);
    formData.append("friend1Name", values.friend1Name);
    formData.append("friend1Phone", values.friend1Phone);
    formData.append("friend2Name", values.friend2Name);
    formData.append("friend2Phone", values.friend2Phone);
    formData.append("location", values.location);
    formData.append("forWho", values.forWho);
    formData.append("instagramHandle", values.instagramHandle);

    let axiosInstance = axios.create({
      baseURL: "https://getlaunchlist.com/s",
    });

    fetch(`http://getlaunchlist.com/s/${process.env.REACT_APP_WAITLIST_KEY}`, {
      method: "POST",
      body: formData,
    });
    axiosInstance
      .post(`/${process.env.REACT_APP_WAITLIST_KEY}`, formData)
      .then((response) => {
        if (response.status === 201 || 200 || 202) {
          console.log("Form submitted successfully");
          setLoading(false);
          window.location.assign(
            `https://getlaunchlist.com/s/${process.env.REACT_APP_WAITLIST_KEY}/${values.email}`
          );
        } else {
          setLoading(false);
          console.error("Form submission failed");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting form:", error);
      });
  }*/

  const navigate = useNavigate();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Helmet>
          <script
            src="https://getlaunchlist.com/js/widget-diy.js"
            type="text/javascript"
            defer
          />
        </Helmet>
        <div className="w-full min-h-screen justify-center homeBack">
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, ease: "easeInOut" }}
            className="p-2 flex flex-col  lg:flex-row p-8 lg: gap-10 space-y-6 max-w-full"
          >
            <div className="logoHolder">
              <div
                id="logo"
                className="flex flex-col justify-center items-left w-full"
              >
                <img
                  src={logo}
                  alt="logo"
                  className="w-[50%] lg:w-[50%]"
                  onClick={() => {
                    navigate("/");
                  }}
                />

                <p className="my-4 text-[#453631] hidden text-2xl lg:block lg:max-w-[325px]">
                  A curated approach to dating.
                </p>
              </div>
            </div>
            <div className="max-w-full form-hold">
              <div className="flex gap-2 mb-6 items-center">
                {step > 0 ? (
                  <Filled
                    onClick={() => setStep(1)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 1 ? (
                  <Filled
                    onClick={() => setStep(2)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 2 ? (
                  <Filled
                    onClick={() => setStep(3)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 3 ? (
                  <Filled
                    onClick={() => setStep(4)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 4 ? (
                  <Filled
                    onClick={() => setStep(5)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 5 ? (
                  <Filled
                    onClick={() => setStep(6)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 6 ? (
                  <Filled
                    onClick={() => setStep(7)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 7 ? (
                  <Filled
                    onClick={() => setStep(8)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
                {step > 8 ? (
                  <Filled
                    onClick={() => setStep(9)}
                    className="cursor-pointer"
                  />
                ) : (
                  <UnFilled />
                )}
              </div>

              <form
                className="max-w-full"
                onSubmit={(e) => {
                  if (step < 9) {
                    e.preventDefault();
                    increaseStep();
                  } else {
                    e.preventDefault();
                    onSubmit(e);
                  }
                }}
              >
                {step === 1 && (
                  <div className="transition-all duration-300 ease-in-out lg:max-w-[60%] ">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      You’re here because someone thinks you have impeccable
                      taste in people.
                    </p>
                    <p className="my-4">
                      Beau is a dating app centered around you and your friends.
                      <br></br>
                      <br /> Applications close 7th May, 11:59pm ET.
                    </p>
                    <p className="my-4">
                      The application will take ~10 mins to complete
                      <span className="notice-star">*</span>. Brevity is valued.
                    </p>
                    <br></br>
                    <p className="notice-font">
                      {" "}
                      <span className="notice-star">*</span>Responses to this
                      application will only be reviewed by the Beau team, they
                      will not be shared, and they will not be used for your
                      profiles.
                    </p>
                  </div>
                )}

                {step === 2 && (
                  <div className="transition-all duration-300 ease-in-out max-w-lg">
                    {" "}
                    <p className="my-6 font-[Garamond]  text-xl lg:text-4xl sm:text text-[#453631]">
                      Tell us a little about yourself
                    </p>
                    <div className="my-5">
                      <input
                        required={true}
                        onChange={handleChange("name")}
                        value={values.name}
                        type="text"
                        placeholder="Your name"
                        className="outline-0 w-full  border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                      />
                    </div>
                    <div className="my-5">
                      <div className="flex gap-2 justify-center">
                        <select
                          id="countryCode"
                          name="countryCode"
                          required
                          value={values.countryCode}
                          onChange={handleChangeNumber("countryCode")}
                          className="outline-0 w-2/6  border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                        >
                          <option value="US +1"> +1 (🇺🇸)</option>
                          <option value="CA +1"> +1 (🇨🇦)</option>
                          <option value="+44">+44 (🇬🇧) </option>
                        </select>
                        <input
                          required
                          type="tel"
                          autoComplete="tel"
                          onChange={handleChangeNumber("phone")}
                          value={values.phone}
                          placeholder="Number"
                          className="outline-0 w-full  pl-0 p-2 border-0 border-b-2 bg-transparent border-black/30 focus:border-black"
                        />
                      </div>
                      {!isPhoneNumberValid && (
                        <p className="text-red-600 text-sm mt-1">
                          Please enter a valid phone number.
                        </p>
                      )}
                    </div>
                    <div className="my-5">
                      <input
                        required
                        type="email"
                        autoComplete="email"
                        onChange={handleChange("email")}
                        value={values.email}
                        placeholder="Email"
                        className="outline-0 w-full  pl-0 p-2 border-0 border-b-2 bg-transparent border-black/30 focus:border-black"
                      />
                    </div>
                    <div className="my-5 flex items-center">
                      <select
                        id="location"
                        name="location"
                        required
                        value={values.location}
                        onChange={handleChange("location")}
                        className="outline-0 w-1/2 border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 activ focus:border-black"
                      >
                        <option
                          disabled
                          selected
                          value=""
                          className="select-placeholder"
                        >
                          Your location
                        </option>
                        <option value="New York">New York</option>
                        <option value="LA">Los Angeles</option>
                         <option value="San Francisco">San Francisco</option>
                        <option value="London">London</option>
                        <option value="Toronto">Toronto</option>
                        <option value="Vancouver">Vancouver</option>
                      </select>

                      <input
                        required
                        id="instagram-handle"
                        className="outline-0 w-full border-0 ml-5 p-2 pb-1.5 border-b-2 bg-transparent border-black/30 focus:border-black"
                        name="instagramHandle"
                        value={values.instagramHandle}
                        onChange={handleChange("instagramHandle")}
                        placeholder="Your Instagram Handle"
                        type="text"
                      />
                    </div>
                    <div className="my-5">
                      <div>
                        <label htmlFor="dob" className="block mb-2">
                          When is your birthday?
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={handleBirthdayChange}
                            className=""
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="transition-all duration-300 ease-in-out max-w-lg">
                    {" "}
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      Are you interested in dating or matchmaking?
                    </p>
                    <div className="my-5">
                      <label htmlFor="">
                        <input
                          required
                          type="radio"
                          className="mr-4 cursor-pointer accent-black"
                          name="interest"
                          value="dating"
                          checked={values.interest === "dating"}
                          onChange={handleChange("interest")}
                        />
                        <span>Dating</span>
                      </label>
                    </div>
                    <div className="my-5">
                      <label htmlFor="">
                        <input
                          required
                          type="radio"
                          className="mr-4 cursor-pointer accent-black"
                          name="interest"
                          value="matchmaking"
                          checked={values.interest === "matchmaking"}
                          onChange={handleChange("interest")}
                        />
                        <span>Matchmaking</span>
                      </label>
                    </div>{" "}
                    <div className="my-5">
                      <label htmlFor="">
                        <input
                          required
                          type="radio"
                          className="mr-4 cursor-pointer accent-black"
                          name="interest"
                          value="both"
                          checked={values.interest === "both"}
                          onChange={handleChange("interest")}
                        />
                        <span>Both</span>
                      </label>
                    </div>
                  </div>
                )}
                {step === 4 && (
                  <div className="transition-all duration-300 ease-in-out max-w-lg">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      Which gender best describes you?
                    </p>
                    <div className="my-3">
                      <select
                        value={values.gender}
                        onChange={handleChange("gender")}
                        required
                        placeholder="Select gender"
                        className="outline-0 w-full border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                      >
                        <option value="">Select gender</option>
                        <option value="male">Man</option>
                        <option value="female">Woman</option>
                        <option value="non-binary">Non-binary</option>
                        <option value="selfID">Other</option>
                      </select>
                    </div>
                    <>
                      {values.gender === "selfID" && (
                        <div className="my-3">
                          <input
                            type="text"
                            name="selfID"
                            id="selfID"
                            value={values.selfID}
                            onChange={handleChange("selfID")}
                            required
                            className="outline-0 w-full border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                            placeholder="What's your gender identity?"
                          />
                        </div>
                      )}
                    </>
                    <>
                      {values.interest !== "matchmaking" && (
                        <div>
                          <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                            Who do you want to see on the app?
                          </p>
                          <div className="my-5">
                            <label htmlFor="">
                              <input
                                type="checkbox"
                                className="mr-4 cursor-pointer accent-black"
                                name="forWho"
                                value="man"
                                onChange={handleItemChange}
                              />
                              <span>Men</span>
                            </label>
                          </div>
                          <div className="my-5">
                            <label htmlFor="">
                              <input
                                type="checkbox"
                                className="mr-4 cursor-pointer accent-black"
                                name="forWho"
                                value="woman"
                                onChange={handleItemChange}
                              />
                              <span>Women</span>
                            </label>
                          </div>
                          <div className="my-5">
                            <label htmlFor="">
                              <input
                                type="checkbox"
                                className="mr-4 cursor-pointer accent-black"
                                name="forWho"
                                value="non-binary"
                                onChange={handleItemChange}
                              />
                              <span>Non-binary people</span>
                            </label>
                          </div>
                          <div className="my-5">
                            <label htmlFor="">
                              <input
                                type="checkbox"
                                className="mr-4 cursor-pointer accent-black"
                                name="forWho"
                                value="other"
                                onChange={handleItemChange}
                              />
                              <span>Other</span>
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                )}
                {step === 5 && (
                  <div className="transition-all duration-300 ease-in-out">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      Warming up to each other
                    </p>
                    <div className="my-5">
                      <label htmlFor="first-date-textarea">
                        Think about the best first date you've ever had. Tell us
                        how it made you feel.
                        <p className="text-sm text-gray-400">50 words max</p>
                        <textarea
                          required
                          id="first-date-textarea"
                          className="outline-0 w-full max-w-lg rounded-md border-0 pl-0 mt-10 pl-2 pt-2 border-2 bg-transparent border-black/30 focus:border-black resize-none"
                          name="firstDate"
                          value={values.firstDate}
                          onChange={handleChange("firstDate")}
                          placeholder="Tell your story..."
                          rows={3}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {step === 6 && (
                  <div className="transition-all duration-300 ease-in-out">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      (Not so?) guilty pleasures
                    </p>
                    <div className="my-5">
                      <label htmlFor="cant-say-no-textarea">
                        What's the one thing you can't say no to?
                        <p className="text-sm text-gray-400">50 words max</p>
                        <textarea
                          required
                          id="cant-say-no-textarea"
                          className="outline-0 w-full max-w-lg rounded-md border-0 pl-0 mt-10 pl-2 pt-2 border-2 bg-transparent border-black/30 focus:border-black resize-none"
                          name="cant-say-no"
                          value={values.cantSayNo}
                          onChange={handleChange("cantSayNo")}
                          placeholder="Tell your story..."
                          rows={3}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {step === 7 && (
                  <div className="transition-all duration-300 ease-in-out">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      Asking the unaskable
                    </p>
                    <div className="my-5">
                      <label htmlFor="ex-feedback-textarea ">
                        If your ex was asked about you (god forbid), what do you
                        think they would say?
                        <p className="text-sm text-gray-400">50 words max</p>
                        <textarea
                          required
                          id="ex-feedback-textarea"
                          className="outline-0 w-full max-w-lg rounded-md border-0 pl-0 mt-10 pl-2 pt-2 border-2 bg-transparent border-black/30 focus:border-black resize-none"
                          name="ex-feedback"
                          value={values.exFeedback}
                          onChange={handleChange("exFeedback")}
                          placeholder="Tell your story..."
                          rows={3}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {step === 8 && (
                  <div className="transition-all duration-300 ease-in-out">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      Let's get deep
                    </p>
                    <div className="my-5">
                      <label htmlFor="beliefs-textarea">
                        How have your beliefs about dating shifted over time?
                        <p className="text-sm text-gray-400">50 words max</p>
                        <textarea
                          required
                          id="beliefs-textarea"
                          className="outline-0 w-full max-w-lg rounded-md border-0 pl-0 mt-10 pl-2 pt-2 border-2 bg-transparent border-black/30 focus:border-black resize-none"
                          name="beliefs"
                          value={values.beliefs}
                          onChange={handleChange("beliefs")}
                          placeholder="Tell your story..."
                          rows={3}
                        />
                      </label>
                    </div>
                  </div>
                )}

                {step === 9 && (
                  <div className="transition-all duration-300 ease-in-out max-w-lg">
                    <p className="my-6 text-xl lg:text-2xl font-[Garamond] text-[#453631]">
                      Time to show off your friends.
                    </p>
                    <p className="my-4">
                      Refer two of your favorite people to Beau, choose wisely. 
                      
                    </p>
                    <p> We'll send them a message inviting them to join Beau.</p>
                    <div>
                      <h3 className="text-xl font-bold">Friend 1</h3>
                      <div className="my-5">
                        <input
                          required
                          id="friend1-name"
                          className="outline-0 w-full border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                          name="friend1Name"
                          value={values.friend1Name}
                          onChange={handleChange("friend1Name")}
                          placeholder="Their Name"
                          type="text"
                        />
                      </div>
                      <div className="flex gap-2 justify-center">
                        <select
                          id="friend1-countryCode"
                          name="friend1CountryCode"
                          required
                          value={values.friend1CountryCode}
                          onChange={handleChangeNumber("friend1CountryCode")}
                          className="outline-0 w-2/6 border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                        >
                          <option value="US +1"> +1 (🇺🇸)</option>
                          <option value="CA +1"> +1 (🇨🇦)</option>
                          <option value="+44">+44 (🇬🇧) </option>
                        </select>
                        <input
                          required
                          type="tel"
                          autoComplete="tel"
                          onChange={handleChangeNumber("friend1Phone")}
                          value={values.friend1Phone}
                          placeholder="Their Number"
                          className="outline-0 w-full pl-0 p-2 border-0 border-b-2 bg-transparent border-black/30 focus:border-black"
                        />
                      </div>
                      {!isFriend1PhoneNumberValid && (
                        <p className="text-red-600 text-sm mt-1">
                          Please enter a valid phone number.
                        </p>
                      )}
                    </div>
                    <div>
                      <br></br>
                      <h3 className="text-xl font-bold">Friend 2</h3>
                      <div className="my-5">
                        <input
                          required
                          id="friend2-name"
                          className="outline-0 w-full border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                          name="friend2Name"
                          value={values.friend2Name}
                          onChange={handleChange("friend2Name")}
                          placeholder="Their Name"
                          type="text"
                        />
                      </div>
                      <div className="flex gap-2 justify-center">
                        <select
                          id="friend2-countryCode"
                          name="friend2CountryCode"
                          required
                          value={values.friend2CountryCode}
                          onChange={handleChangeNumber("friend2CountryCode")}
                          className="outline-0 w-2/6 border-0 pl-0 p-2 border-b-2 bg-transparent border-black/30 focus:border-black"
                        >
                          <option value="US +1"> +1 (🇺🇸)</option>
                          <option value="CA +1"> +1 (🇨🇦)</option>
                          <option value="+44">+44 (🇬🇧) </option>
                        </select>
                        <input
                          required
                          type="tel"
                          autoComplete="tel"
                          onChange={handleChangeNumber("friend2Phone")}
                          value={values.friend2Phone}
                          placeholder="Their Number"
                          className="outline-0 w-full pl-0 p-2 border-0 border-b-2 bg-transparent border-black/30 focus:border-black"
                        />
                      </div>
                      {!isFriend2PhoneNumberValid && (
                        <p className="text-red-600 text-sm mt-1">
                          Please enter a valid phone number.
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {step === 10 && (
                  <div className="transition-all duration-300 ease-in-out max-w-lg">
                    <p className="my-6 text-xl lg:text-2xl lg:max-w-[90%] font-[Garamond] text-[#453631] max-w-lg">
                      Thank you for the time and energy you put into your
                      application, we can't wait to read it. <br /> <br /> If we
                      think you'd be a good fit, we'll be in touch by mid-May at
                      the latest.
                    </p>

                    <p className="my-6 text-xl lg:text-2xl lg:max-w-[90%] font-[Garamond] text-[#453631]">
                      Your position in the waitlist is: #
                      <strong>{waitlistPosition}</strong>
                    </p>
                  </div>
                )}
                {step < 10 && (
                  <div className="flex gap-4 mt-12">
                    {step > 1 && (
                      <button
                        id="back"
                        type="button"
                        onClick={() => {
                          setStep(step - 1);
                        }}
                        className="bg-transparent relative shadow-md hover:shadow-[#453631] p-2 px-6 border border-[#00000026] rounded-lg ease-out hover:translate-y transition-all"
                      >
                        Back
                      </button>
                    )}
                    <button
                      id="next"
                      disabled={loading || isUnderage}
                      type="submit"
                      className={`bg-transparent relative p-2 px-6 border rounded-lg ${
                        loading || isUnderage
                          ? "border-gray-400 cursor-not-allowed"
                          : "shadow-md hover:shadow-[#453631] border-[#00000026] ease-out hover:translate-y transition-all"
                      }`}
                    >
                      {loading ? (
                        <>Submitting</>
                      ) : (
                        <> {step === 9 ? "Submit" : "Next"} </>
                      )}
                    </button>

                    {isUnderage && (
                      <p className="mt-4 text-red-600">
                        You must be at least 18 years old to proceed with the
                        application.
                      </p>
                    )}
                  </div>
                )}
              </form>
            </div>
          </m.div>
        </div>
      </div>
    </LocalizationProvider>
  );
};
